body {
  @apply font-body
  text-primary-black;
}

.text-btn {
  @apply font-btn;
}

.modal-content-text {
  @apply text-center;
}

.modal-title {
  @apply text-md
  text-center;
}

.modal-button {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

.modal-bg {
  background: #221f44;
  opacity: 0.9;
}
