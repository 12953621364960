.icon-wrapper {
  @apply inline-flex items-center justify-center w-14 h-14 rounded-full
}

.base-timer {
  @apply
  relative
  w-16
  h-16
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: #CECDD9;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(-90deg) scale(-1, 1);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__label {
  @apply
  flex
  absolute
  w-16
  h-16
  top-0
  items-center
  justify-center
}
