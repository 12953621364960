.form-input-wrapper.error input.form-input,
.form-input-wrapper.error select.form-input {
  @apply border-validation-error
  text-validation-error;
}

.form-input-wrapper input.form-input.otp {
  @apply pt-1
  text-md
  text-center
  font-xl;
}

.form-input-wrapper.error select:focus ~ label,
.form-input-wrapper.error input:focus-within ~ label,
.form-input-wrapper.error input:not(:placeholder-shown) ~ label,
.form-input-wrapper.error select:not([value=""]):valid ~ label,
.form-input-wrapper.error .form-label {
  @apply text-validation-error;
}

.form-input-wrapper.success input.form-input,
.form-input-wrapper.success select.form-input {
  @apply border-validation-confirmation
  text-validation-confirmation;
}

.form-input-wrapper.success select:focus ~ label,
.form-input-wrapper.success input:focus-within ~ label,
.form-input-wrapper.success input:not(:placeholder-shown) ~ label,
.form-input-wrapper.success select:not([value=""]):valid ~ label,
.form-input-wrapper.success .form-label {
  @apply text-validation-confirmation;
}

.form-input-wrapper {
  @apply relative
  border
  bg-white
  z-0
  border-0;
}

.form-input-wrapper textarea.placeholder-textarea {
  @apply p-4;
}

.form-input-wrapper textarea.form-input {
  @apply h-36;
}

textarea.form-input,
select.form-input,
input.form-input:not([type="checkbox"]) {
  @apply block
  pt-6
  py-4
  pb-1.5
  w-full
  text-base
  border-secondary-lines
  appearance-none
  h-14
  focus:border-2
  focus:outline-none
  focus:border-secondary-primary
  focus:ring-transparent;
}
.form-input-date {
  @apply block
 py-4
w-full
text-base
border-secondary-lines
appearance-none
h-14
focus:border-2
focus:outline-none
   focus:border-secondary-primary
 focus:ring-transparent;
}

textarea + .form-label,
select + .form-label,
input.form-input:not([type="checkbox"]) + .form-label {
  @apply absolute
  top-2
  text-base
  py-2
  px-4
  -z-1
  duration-300
  text-secondary-secondary
  origin-0;
}

input[type="radio"]:disabled {
  @apply border-secondary-inactive;
}

input[type="radio"].success:disabled {
  @apply bg-validation-confirmation
  border-validation-confirmation;
}

input[type="radio"].error:disabled {
  @apply bg-validation-error
  border-validation-error;
}

input[type="radio"]:disabled:not(.success),
input[type="radio"]:disabled:not(.error) + div {
  @apply text-secondary-inactive;
}

input[type="checkbox"].form-input,
input[type="radio"].form-input {
  @apply checked:text-primary-black
  border-primary-black;
}

.form-label b {
  @apply text-secondary-titles
  font-bold;
}

.form-input-wrapper textarea:focus ~ label,
.form-input-wrapper select:focus ~ label,
.form-input-wrapper input:not([type="checkbox"]):focus-within ~ label,
.form-input-wrapper
  textarea:not(:placeholder-shown):not([type="checkbox"])
  ~ label,
.form-input-wrapper
  input:not(:placeholder-shown):not([type="checkbox"])
  ~ label,
.form-input-wrapper select:not([value=""]):valid ~ label {
  @apply transform
  scale-75
  -translate-y-1
  z-0
  ml-2.5
  px-1
  py-0;
}

.widget-events::after,
.widget-calculation::after {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(34, 31, 68, 0) 0%,
    rgba(34, 31, 68, 0.64) 100%
  );

  @apply absolute
  inset-0
  z-10
  opacity-5;
}

.widget-events > div,
.widget-calculation > div {
  @apply z-20;
}

.widget-events {
  background-image: url("/public/assets/img/events.jpg");

  @apply relative
  aspect-w-4
  aspect-h-3
  bg-cover
  w-full;
}

.widget-calculation {
  background-image: url("/public/assets/img/calculator.jpg");

  @apply relative
  aspect-w-4
  aspect-h-3
  bg-cover
  w-full;
}

.toggle input:checked ~ .block {
  background-color: #1dc066;
}

/* Toggle B */
.toggle input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}

.autoComplete_wrapper {
  @apply w-full
  relative;
}

.autoComplete_wrapper #autoComplete:focus {
  @apply shadow-none
  border-secondary-lines;
}

.autoComplete_wrapper > ul {
  @apply absolute
  top-full
  w-full
  sm:px-12
  sm:pt-6
  sm:pb-6
  sm:border
  border-secondary-lines
  z-10
  bg-white
  md:max-h-[50vh]
  overflow-y-auto
  overscroll-contain
  h-[70vh]
  sm:h-auto;
}

.autoComplete_wrapper > ul a.form-submit-btn {
  @apply w-full
  max-w-none
  sm:max-w-[14rem]
  absolute
  sm:static
  bottom-0;
}

.autoComplete_wrapper > ul li {
  @apply py-4
  border-b
  border-secondary-lines;
}

.autoComplete_wrapper > ul li mark {
  @apply font-bold
  bg-transparent;
}

.autoComplete_wrapper > ul li a:hover mark {
  @apply text-primary-red;
}
