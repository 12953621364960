.link-icon-wrapper {
  @apply
  mb-3
  h-20
  w-20
  flex
  items-center
  justify-center
}

.link-icon {
  @apply
  w-10
  h-10
  focus:outline-none
  text-primary-black
}
.nav-link-icon {
  @apply
  w-16
  h-16
  focus:outline-none
  text-primary-black
  pt-4
  pb-6
}


.slider-arrow {
  @apply
  bg-white
  flex
  rounded-none
  justify-center
  items-center
  w-12
  h-12
  focus:outline-none
}

.slider-bottom-arrow {
  @apply
  after:w-6
  after:h-6
  after:-bottom-3
  after:bg-background-purple
  after:block
  after:absolute
  after:left-0
  after:right-0
  after:mx-auto
  after:-rotate-45
}

@screen md {
  .slider-bottom-arrow {
    @apply
    after:w-12
    after:h-12
    after:-bottom-6
  }
}
@media (max-width: 768px) {
  .list-none{
    @apply
    before:hidden
    mr-0
  }
}


.list-dot {
  @apply
  before:absolute
  before:w-1
  before:h-1
  before:-left-3
  before:top-1/2
  before:bg-secondary-lines
  before:rounded-full
}

.list-dot.black-dot {
  @apply
  before:bg-secondary-primary
  before:-m-0.5
}

.circle {
  border: 30px solid #6258C9;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.video-wrapper .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.message-user:hover {
  background: transparent !important;
}

.message-user {
  @apply
  border-secondary-lines !important
}

/* .menu-item > div  {
  flex-grow: 1;
  flex-basis: 0;
} */

.menu-item > div > a{
  @apply
  hover:text-primary-red
  transition
}

.play-icon {
  background: rgba(255, 255, 255, 0.32);
  backdrop-filter: blur(4px);
}

.triangle:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid #F7F7FC;
  position: absolute;
  transform: rotate(135deg);
  margin-top: -6px;
  left: -8px;
  top: 0px;
}

.triangle-reverse:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid #F7F7FC;
  position: absolute;
  transform: rotate(225deg);
  left: -8px;
  bottom: -6px;
}

.triangle-right:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid #221F44;
  position: absolute;
  transform: rotate(315deg);
  right: -8px;
  bottom: -6px;
}

.selected-image {
  @apply
  relative
  border-primary-red
  border-2
  after:absolute
  after:top-0
  after:left-0
  after:w-full
  after:h-full
  after:bg-[rgba(233,64,52,0.2)]
}

.selected-image .select-icon {
  @apply
  absolute
  left-0
  right-0
  top-0
  bottom-0
  w-20
  h-20
  items-center
  flex
  justify-center
  mx-auto
  my-auto
  bg-[rgba(255,255,255,0.32)]
  /* bg-white */
  backdrop-blur-sm
  z-10
}

.goverlay {
  background: rgb(34, 31,68, .9);
}

.swiper-pagination-horizontal.swiper-pagination {
  @apply
  bottom-auto
  /* top-[279px] */
  text-right
  pr-3
}

@media (max-width: 490px) {
  .swiper-pagination-horizontal.swiper-pagination {
    top: 270px;
  }
}

@media screen and (min-width: 490px) and (max-width: 990px) {
  .swiper-pagination-horizontal.swiper-pagination {
    top: 200px;
  }
}
.swiper-pagination-horizontal.swiper-pagination .swiper-pagination-bullet {
  @apply
  rounded-none
  bg-primary-red
}
