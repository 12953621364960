@import url("partials/typography.css");
@import url("partials/forms.css");
@import url("partials/footer.css");
@import url("partials/modal.css");
@import url("partials/glightbox.min.css");
@import url("../../../node_modules/air-datepicker/air-datepicker.css");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com");
@import url("https://fonts.gstatic.com");
@import url("pages/homepage.css");
/* @import url('./vendors/swiper.css'); */

@tailwind base;
@tailwind components;

input[type="text"]:focus {
  box-shadow: none;
}

.form-submit-btn {
  @apply w-full
  flex
  justify-center
  py-4
  px-8
  border
  border-transparent
  text-btn
  font-btn
  text-white
  bg-primary-red
  focus:outline-none
  focus:ring-primary-red
  max-w-[14rem]
  antialiased
  transition
  duration-300
  uppercase;
}

.tab-btn {
  @apply w-full
  flex
  justify-center
  py-2
  px-2
  border
  border-transparent
  text-white
  bg-primary-red
  focus:outline-none
  focus:ring-primary-red
  leading-[25px]
  antialiased
  transition
  duration-75
  relative
  after:w-[1px]
  after:h-[80%]
  after:bg-secondary-lines
  after:absolute
  after:top-[10%]
  after:bottom-0
  after:right-0;
}

.tab-btn.active {
  @apply before:w-[1px]
  before:h-[80%]
  before:bg-secondary-secondary-bg
  before:absolute
  before:top-[10%]
  before:bottom-0
  before:left-[-3px];
}

.tab-btn:last-child,
.tab-btn.active {
  @apply after:hidden;
}

.form-submit-btn:hover,
.btn:hover {
  background-color: #eb5348;
  @apply transition
  duration-300;
}

.form-submit-btn.revert {
  @apply bg-white
  border-primary-red
  text-primary-red
  border;
}
.form-submit-btn.revert:hover {
  background-color: #eb5348;
  @apply text-white
  border;
}

.btn {
  @apply inline-flex
  justify-center
  py-4
  border-primary-black
  border-2
  px-8
  text-btn
  font-btn
  focus:outline-none
  antialiased
  uppercase
  transition
  w-full
  sm:w-auto;
}

.btn.white {
  @apply border-primary-red
  bg-primary-red
  text-white;
}

.btn.with-icon {
  @apply inline-flex
  items-center
  justify-center
  px-4
  py-2
  text-white
  bg-primary-red
  outline-none
  border-transparent
  text-btn;
}

.btn:hover {
  @apply bg-primary-red
  border-primary-red
  text-white;
}

.btn:hover svg {
  @apply text-white;
}

@tailwind utilities;

html,
body {
  @apply w-full font-outfit;
}

html {
  @apply scroll-smooth;
}

* {
  font-family: 'Outfit', sans-serif !important;
}

li a:not(.form-submit-btn):not(.btn) {
  @apply transition-colors;
}

li a:not(.form-submit-btn):not(.btn):hover {
  @apply text-primary-red;
}

.air-datepicker-cell.-selected-.-focus-,
.air-datepicker-cell.-selected-,
.air-datepicker-cell.-selected-:hover {
  @apply bg-primary-red;
}

.air-datepicker-cell {
  @apply text-body-s
  text-primary-black;
}

.air-datepicker-body--day-name {
  @apply text-primary-blue;
}

.cdc {
  @apply h-auto
  relative
  flex-shrink-0;
}

.cdc,
.cdc-overlay {
  transform: translateZ(0);
  @apply rounded-full
  overflow-hidden;
}

.cdc-overlay {
  @apply absolute
  opacity-100
  flex
  items-center
  justify-center;
}

.cdc-text {
  @apply text-center;
}

.air-datepicker-cell {
  @apply text-body-s
  text-primary-black;
}

.air-datepicker-body--day-name {
  @apply text-primary-blue;
}

.cdc-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cdc-legend-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cdc-legend-item-color {
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5em;
}

.cdc-sections {
  @apply absolute
  h-auto
  w-full
  pb-[100%]
  rounded-full;
}

.cdc-section {
  width: 50%;
  overflow: hidden;
  background-color: transparent;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  pointer-events: none;
}

.cdc-filler,
.cdc-section {
  @apply absolute
  h-full;
}

.cdc-filler {
  @apply w-full
  pointer-events-auto;
}

.cdc-section.cdc-section-right {
  left: 50%;
}

.cdc-section.cdc-section-right .cdc-filler {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.cdc-section.cdc-section-left {
  @apply left-0;
}

.cdc-section.cdc-section-left,
.cdc-section.cdc-section-left .cdc-filler {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.modal-body {
  @apply flex
  flex-col
  h-full
  justify-center
  items-center;
}

#glightbox-body .gbtn.gnext,
#glightbox-body .gbtn.gprev {
  @apply bg-primary-red rounded-none w-[72px];
}

#glightbox-body .gbtn.gclose {
  @apply opacity-100
  bg-transparent;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 50px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.rs-content .rs-search{
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  -ms-flex-direction: row;
  padding: 8px 8px 6px;
}

.userContract {
  text-decoration: underline;
  color: #eb5348;
}